import React, { useState } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";

import { Link } from "@components/shared/sub";
import { Maybe, Query, SanityConsultations } from "@graphql-types";
import { Container, GridContainer, H2, H3, P } from "@util/standard";
import { colors, MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";

const Wrapper = styled(Container)`
  margin: 130px 0;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 60px 0;
  }
`;

const Clickable = styled(Container)<{ isSelected?: boolean }>`
  cursor: pointer;
  user-select: none;
  margin-right: 30px;
  padding: ${(props) => (props.padding ? props.padding : `10px 35px`)};
  background-color: ${(props) =>
    props.isSelected ? colors.darkBlue : colors.transparent};

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: fit-content;
  }
`;

const Text = styled.p<{ isSelected?: boolean }>`
  margin: 0;
  font-size: 20px;
  opacity: ${(props) => (props.isSelected ? 1 : 0.4)};
  color: ${(props) => (props.isSelected ? colors.white : colors.darkBlue)};
`;

const ClickableContainer = styled(Container)`
  margin-bottom: 80px;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column;
    margin-bottom: 50px;
  }
`;

const AllConsultations = () => {
  const {
    allSanityConsultations,
    allSanityConsultationType,
  }: Query = useStaticQuery(graphql`
    {
      allSanityConsultations {
        nodes {
          title
          file {
            asset {
              url
            }
          }
          description
          link {
            url
            newwindow
            linktext
            internallink
          }
          _id
          category {
            _id
          }
        }
      }
      allSanityConsultationType {
        nodes {
          _id
          category {
            title
          }
        }
      }
    }
  `);

  const [filter, setFilter] = useState("all");

  const handleClick = (id?: string | null) => {
    setFilter(id ?? "all");
  };

  const Consul = (props: Maybe<SanityConsultations>) => {
    if (props == null) {
      return null;
    }
    return (
      <Container flexDirection="column">
        <H3 margin="0">{props.title}</H3>
        <P>{props.description}</P>

        <Container justifyContent="space-between">
          <Link isUnderlined {...props.link} color="darkBlue" opacity={0.5} />
          <Link
            download
            newwindow
            isUnderlined
            linktext="Download"
            url={props.file?.asset?.url}
            color="darkBlue"
            opacity={0.5}
          />
        </Container>
      </Container>
    );
  };

  const AllConsuls = () => {
    if (data == null || data.length === 0) {
      return <H2 margin="0">Consultation Coming Soon</H2>;
    }

    return (
      <GridContainer repeat={3} mobileRepeat={1} rowGap="30px" columnGap="80px">
        {data.map((consul) => {
          // @ts-ignore
          return <Consul key={consul?._id} {...consul} />;
        })}
      </GridContainer>
    );
  };

  const data =
    filter === "all"
      ? allSanityConsultations.nodes
      : allSanityConsultations.nodes.filter(
          (type) => type.category?._id === filter
        );

  return (
    <Wrapper>
      <Container flexDirection="column" width="70%" margin="auto">
        <ClickableContainer>
          <Clickable
            isSelected={filter === "all"}
            onClick={() => handleClick("all")}
          >
            <Text isSelected={filter === "all"}>All Consult</Text>
          </Clickable>
          {allSanityConsultationType.nodes.map((type) => {
            if (type == null) {
              return null;
            }

            return (
              <Clickable key={type._id} isSelected={filter === type._id}>
                <Text
                  isSelected={filter === type._id}
                  onClick={() => handleClick(type._id)}
                >
                  {type.category?.title}
                </Text>
              </Clickable>
            );
          })}
        </ClickableContainer>
        <AllConsuls />
      </Container>
    </Wrapper>
  );
};

export default AllConsultations;
