import React from "react";
import { graphql, PageProps } from "gatsby";

import ContactUsBlock from "@components/contact/contactUsBlock";
import SEO from "@components/shared/seo";
import { Query } from "@graphql-types";
import ConsultationsHero from "@components/consultations/consultationHero";
import AllConsultations from "@components/consultations/allConsultations";

interface Props extends PageProps {
  data: Query;
}

export default function Consultations({ data }: Props) {
  const { sanityConsultationsPage } = data;

  return (
    <div>
      <SEO seoData={sanityConsultationsPage?.seo} />
      <ConsultationsHero />
      <AllConsultations />
      <ContactUsBlock />
    </div>
  );
}

export const query = graphql`
  query ConsultationsQuery {
    sanityConsultationsPage {
      seo {
        pageDescription
        pageKeyWords
        pageTitle
        slug {
          current
        }
        ogImage {
          asset {
            url
          }
        }
      }
    }
  }
`;
